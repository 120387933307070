<template>
    <!-- <div style="z-index:2" data-aos="fade-in"> -->
    <div>
        <!-- <v-row class="mt-4" justify="center"> -->
        <!-- <v-col cols=12 class="text-center d-flex justify-center"> -->
        <v-card :color="card_positive" class="pb-12 text-center fill-height px-3">
            <!-- <h2 class="text-left ma-4">
                        <vue-typer :text="[$t('contactus.title'), ]" :repeat="0" :shuffle="false"
                            initial-action="typing" :pre-type-delay="500" :type-delay="20" :pre-erase-delay="2000"
                            :erase-delay="250" erase-style="select-all" :erase-on-complete="false"
                            caret-animation="solid"></vue-typer>
                    </h2> -->

            <div class="d-flex justify-center">
                <v-img data-aos="fade-in" class="" :max-width="image_size" alt="Logo"
                    src="@/assets/imgs/contact_01.png" />
            </div>
            <h2 v-if="!step_contact_sent" class="" :style="title_size">
                {{ $t('contactus.title') }}
            </h2>
            <h2 v-if="step_contact_sent" class="" :style="title_size">
                {{ $t('contactus.message.thankyou') }}
            </h2>

            <div class="d-flex flex-column justify-center align-center">
                <v-card width=500>
                    <v-card-text v-if="!step_contact_sent">
                        <v-select v-model="type" :color="style_modalita == ' dark' ? 'secondary' : 'primary'"
                            :items="interestedIn" :label="$t('contactus.field.interestedin')" outlined>
                            <template slot="item" slot-scope="data">
                                <span class="secondary--text">{{ data.item.text }}</span>
                            </template>
                        </v-select>
                        <v-text-field v-model.trim="name" :maxlength="nameMaxLength" counter color="secondary"
                            :label="$t('contactus.field.name')" outlined />
                        <v-text-field color="secondary" v-model.trim="email" :rules="emailRules"
                            :label="$t('contactus.field.email')" outlined />
                        <v-textarea v-model.trim="message" :maxlength="messageMaxLength" counter color="secondary"
                            :label="$t('contactus.field.message')" outlined />

                        <v-checkbox color="secondary" v-model="agreement" label="" dense class="mt-0">
                            <template v-slot:label>
                                <div>
                                    <span :style="textColor" style="font-size: 0.8rem">
                                        {{ $t('contactus.field.agreement') }}</span>
                                </div>
                            </template>
                        </v-checkbox>

                    </v-card-text>
                    <v-card-actions v-if="!step_contact_sent">
                        <v-btn
                            :disabled="agreement == false || sending || nameError || emailError || message.length < 5 || !type"
                            color="secondary" block @click="sendContact()">
                            <span class="primary--text">
                                {{ $t('contactus.button.send') }}
                            </span>
                        </v-btn>

                    </v-card-actions>
                </v-card>
            </div>
            <v-card-text v-if="step_contact_sent" class="pt-6">
                <!-- <strong>{{ $t('contactus.message.thankyou') }}</strong>
                <br> -->
                <p style="font-size:1.2rem">{{ $t('contactus.message.contactsent') }}</p>
            </v-card-text>
        </v-card>
        <!-- </v-col> -->
        <!-- </v-row> -->
    </div>
    <!-- </div> -->
</template>

<script>

import { VueTyper } from "vue-typer";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init()

import emailjs from '@emailjs/browser';
emailjs.init("a9O_yA0Z17rDcCFYC");


export default {
    props: {},
    components: {
        VueTyper
    },
    metaInfo: {
        title: 'Contattaci',
    },
    data: () => ({
        type: '',
        email: '',
        emailRules: [],
        emailError: true,
        name: '',
        nameError: true,
        nameMaxLength: 30,
        message: '',
        messageMaxLength: 250,
        agreement: false,
        // 
        sending: false,
        step_contact_sent: false,
    }),
    methods: {

        clearForm() {
            this.name = ''
            this.email = ''
            this.message = ''
            this.type = null
        },
        sendContact() {

            this.sending = true
            const payload = {
                nome: this.name,
                email: this.email,
                messaggio: this.message,
                tipo: this.type,
                data: new Date(),
                stato: 0,
                delete: false
            }

            this.$store.dispatch("contacts/act_write_new_contact", payload).then(() => {


                // Send email

                var templateParams = {
                    nome: this.name,
                    email: this.email,
                    interesse: this.type,
                    messaggio: this.message,
                };

                emailjs.send('Consorzio1980_support', 'Consorzio1980_contact_tm', templateParams)
                    .then(function (response) {
                        console.log('SUCCESS!', response.status, response.text);
                    }, function (error) {
                        console.log('FAILED...', error);
                    });



                setTimeout(() => {
                    this.step_contact_sent = true
                    this.clearForm()
                }, 1000);
            });


        }
    },
    mounted() { },
    created() { },
    computed: {
        textColor() {
            if (this.isDark) {
                return 'color: #ffffff'
            } else {
                return 'color: #0e0e0e'
            }
        },
        image_size() {
            if (this.isSmallMobile) {
                return 200
            } else {
                return 200
            }
        },
        title_size() {
            if (this.isSmallMobile) {
                return 'font-size: 2.4rem'
            } else {
                return 'font-size: 3.2rem'
            }
        },

        interestedIn() {
            return [

                {
                    value: "Servizi logistici",
                    text: this.$t('contactus.field.interest.logisticsServices')
                },
                {
                    value: "Servizi trasporto",
                    text: this.$t('contactus.field.interest.transportServices')
                },
                {
                    value: "Lavorare con voi",
                    text: this.$t('contactus.field.interest.workwith')
                },
                {
                    value: "Diventare fornitore",
                    text: this.$t('contactus.field.interest.becomeSupplier')
                },
                {
                    value: "Altro...",
                    text: this.$t('contactus.field.interest.other')
                },                
                // {
                //     value: "Candidatura",
                //     text: this.$t('contactus.field.interest.applyposition')
                // },
                // {
                //     value: "Altre informazioni",
                //     text: this.$t('contactus.field.interest.workwith')
                // },
                // {
                //     value: "Lavorare con voi",
                //     text: this.$t('contactus.field.interest.other')
                // },
            ]
        }
    },
    watch: {
        email: function (mail) {
            if (mail !== '') {
                // this.emailRules = [v => (v.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) || this.$t('contactus.error.invalidemail')]
                this.emailRules = [v => {
                    if (v.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                        this.emailError = false
                        return null
                    } else {
                        this.emailError = true
                        return this.$t('contactus.error.invalidemail')
                    }
                }
                ]

            } else if (mail === '') {
                this.emailRules = []
            }
        },
        name: function (name) {
            if (name.length > 0) {
                this.nameError = false
                // this.nameRules = [value => value.length <= 25 || this.$t('contactus.error.charcount', { char: 25 })]
                this.nameRules = [value => {
                    if (value.length <= 25) {
                        this.nameError = false
                        return null
                    } else {
                        this.nameError = true
                        this.$t('contactus.error.charcount', { char: 25 })
                    }
                }]
            } else if (name === '') {
                this.nameError = true
                this.nameRules = []
            }
        },
    }
}
</script>

<style>
.vue-typer .custom.char.typed {
    color: var(--primary);
}
</style>